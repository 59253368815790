.content-section-wrapper .content-section-inner {
  @apply w-full inline-flex flex-col items-center justify-between gap-12 md:gap-20;
}
.content-section-wrapper .content-section-inner .content-section-copy {
  @apply flex flex-col md:flex-row md:gap-20 items-start justify-between w-full;
}
.content-section-wrapper .content-section-inner .content-section-copy .title {
  @apply lg:shrink-0;
}
.content-section-wrapper .content-section-inner .content-section-copy .body {
  @apply max-w-3xl;
}
.content-section-wrapper .content-section-inner .content-section-copy .body *:last-of-type {
  @apply mb-0;
}
.content-section-wrapper .content-section-inner .content-section-copy p:last-of-type {
  @apply mb-0;
}
.content-section-wrapper .content-section-inner .content-section-sidebar {
  @apply w-full;
}
.content-section-wrapper .content-section-inner.variation-content-images {
  @apply flex-col lg:flex-row-reverse;
}
.content-section-wrapper .content-section-inner.variation-content-images.flip-layout {
  @apply lg:flex-row;
}
.content-section-wrapper .content-section-inner.variation-content-images .content-section-copy {
  @apply flex-col xs:flex-col gap-0 lg:w-1/2;
}
.content-section-wrapper .content-section-inner.variation-content-images .content-section-copy .body {
  @apply max-w-none;
}
.content-section-wrapper .content-section-inner.variation-content-images .content-section-sidebar {
  @apply lg:w-1/2;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper {
  @apply flex flex-wrap gap-10;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card {
  @apply p-0 border-none overflow-hidden rounded-md relative w-full md:w-[calc(50%-1.25rem)] max-w-full shadow-sm;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card img {
  @apply transition scale-100 aspect-square max-h-[350px] lg:max-h-[500px];
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card .card-content {
  @apply transition-all absolute bottom-6 left-4 right-4 z-20;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card .card-content .card-content-title {
  @apply inline-flex items-center gap-3 sm:gap-6 m-0 text-white;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card .card-content .card-content-title svg {
  @apply w-4 sm:w-6 h-auto shrink-0;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:before {
  content: "";
  @apply transition-all rounded-md bg-blend-multiply bg-gradient-to-t from-body absolute bottom-0 left-0 w-full h-20 sm:h-32 z-10;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:first-of-type, .content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:nth-child(4n) {
  @apply w-full;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:first-of-type img, .content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:nth-child(4n) img {
  @apply lg:aspect-video;
}
.content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:hover img, .content-section-wrapper .content-section-inner.variation-content-cards .cards-wrapper .card:focus img {
  @apply scale-110;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider {
  @apply relative h-[24rem] sm:h-[45rem] w-full aspect-[2/1] overflow-hidden rounded-md;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .swiper,
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .swiper-wrapper {
  @apply w-full h-full;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .slide-content-wrapper {
  @apply absolute bottom-6 sm:bottom-12 left-6 right-6 z-20 flex items-end justify-between gap-6 flex-wrap;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .title {
  @apply text-2xl sm:text-4xl mb-0 text-white;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .btn {
  @apply text-sm sm:text-lg m-0 mt-6;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .swiper-slide {
  @apply relative;
}
.content-section-wrapper .content-section-inner.variation-content-portfolio .portfolio-slider .swiper-slide:before {
  content: "";
  @apply bg-gradient-to-t from-body absolute bottom-0 left-0 w-full h-1/2 sm:h-1/2 md:h-1/4 bg-blend-multiply z-20;
}
.content-section-wrapper .content-section-inner.variation-content-form form {
  @apply bg-[#f0f0f0] p-6 mx-auto rounded-lg;
}
.content-section-wrapper .content-section-inner.variation-content-form form p {
  @apply m-0;
}